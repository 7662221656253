<template>
  <div style="height: 100%;" >
    <vue-headful :title="pageTitle" />

    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">
        {{ pageheading.toLocaleUpperCase() }}
      </h1>
    </div>

    <Loading v-if="isLoading"/>
    <div style="height: 100%; width: 100%!important;" id="body-content-area" v-else>

      <div id="pagelayout">

        <div class="button-group">
          <button :disabled="!$hasPermissions(clientSession, ['PERMISSION_TEMPLATES'], 2)" class="button is-accent" @click="newTemplate">
            <span class="icon">
              <i class="fas fa-plus-square"></i>
            </span>
            <span>
              Add Template
            </span>
          </button>
          <button class="button is-dark" @click="showDeleteModal = true" :disabled="selectedFields.length === 0 || !$hasPermissions(clientSession, ['PERMISSION_TEMPLATES'], 2)">
            <span class="icon">
              <i class="fas fa-trash"></i>
            </span>
            <span>
              Delete Template{{selectedFields.length > 1 ? 's' : ''}}
            </span>
          </button>
        </div>

        <Grid
          ref="grid"
          v-if="maxHeight > 0"
          :style="{height: maxHeight + 'px'}"
          :data-items="sortedTemplates"
          :selected-field="selectedField"
          :sortable="true"
          :sort="sort"
          :filterable="false"
          :pageable="pageable"
          :page-size="pageSize"
          :skip="skip"
          :take="take"
          :total="totalRecords"
          :columns="columns"
          @pagechange="pageChangeHandler"
          @sortchange="sortChangeHandler"
          @selectionchange="onSelectionChange"
          @headerSelectionchange="onHeaderSelectionChange"
        >

            <template v-slot:name="{props}">
              <td>
                <router-link class="text-is-accent" :to="{ name: 'PermissionTemplateEditPage', params: { id: props.dataItem.id } }">
                  {{props.dataItem.name}}
                </router-link>
              </td>
            </template>

            <template v-slot:updated-at="{props}">
              <td>
                {{moment(props.dataItem.updated_at).format("MM/DD/YYYY h:mm A")}}
              </td>
            </template>

            <template v-slot:selected="{props}">
              <td>
                <input type="checkbox" class="k-checkbox" v-model="props.dataItem.selected" @click="toggleItem(props.dataItem)" />
              </td>
            </template>

            <template v-slot:checkbox>
              <input type="checkbox" class="k-checkbox" v-model="areAllSelected" @click="toggleSelects" />
            </template>

        </Grid>
      </div>
    </div>

    <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
              <div class="modal-background" @click="showDeleteModal = false"></div>
              <div class="modal-card">
              <header class="modal-card-head has-bg-danger">
                  <div class="modal-card-title has-bg-danger">Confirm Delete</div>
                  <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
              </header>
              <section class="modal-card-body">
                  <div class="content">
                  Type "<b>delete</b>" to confirm that you want to delete {{selectedFields.length > 1 ? 'these' : 'this'}} template{{selectedFields.length > 1 ? 's' : ''}}.
                  </div>
                  <div class="field">
                  <div class="control">
                      <input
                      type="text"
                      class="input"
                      placeholder="type `delete` and then click confirm"
                      v-focus
                      v-model.trim="confirmationText"
                      @keydown.enter.prevent="deleteIfConfirmed"
                      />
                  </div>
                  </div>
              </section>
              <footer class="modal-card-foot">
                  <a
                  class="button is-danger"
                  @click="deleteIfConfirmed"
                  :disabled="confirmationText !== 'delete'"
                  >
                  <span class="icon">
                      <i class="fal fa-trash-alt"></i>
                  </span>
                  <span>Confirm</span>
                  </a>
                  <a class="button" @click="showDeleteModal = false">Cancel</a>
              </footer>
              </div>
          </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import Loading from './components/Loading.vue'
import { Grid } from '@progress/kendo-vue-grid'
import moment from 'moment-timezone'
import _ from 'underscore'

const response = {
  status: 200,
  data: [
    {
      id: 1,
      name: 'OSR Permission Template',
      description: 'OSR Basic Permissions. Use for OSR employees.',
      createdAt: '2018-01-01T00:00:00.000Z',
      updatedAt: '2018-01-01T00:00:00.000Z'
    },
    {
      id: 2,
      name: 'Staff Permission Template',
      description: 'Staff Basic Permissions. Use for Staff employees.',
      createdAt: '2018-01-01T00:00:00.000Z',
      updatedAt: '2018-01-01T00:00:00.000Z'
    },
    {
      id: 3,
      name: 'Admin Permission Template',
      description: 'Admin Basic Permissions. Use for Admin employees.',
      createdAt: '2018-01-01T00:00:00.000Z',
      updatedAt: '2018-01-01T00:00:00.000Z'
    },
    {
      id: 4,
      name: 'Super Admin Permission Template',
      description: 'Super Admin Basic Permissions. Use for Super Admin employees.',
      createdAt: '2018-01-01T00:00:00.000Z',
      updatedAt: '2018-01-01T00:00:00.000Z'
    },
    {
      id: 5,
      name: 'Test Permission Template',
      description: 'Test Basic Permissions. Use for Test employees.',
      createdAt: '2018-01-01T00:00:00.000Z',
      updatedAt: '2018-01-01T00:00:00.000Z'
    }
  ]
}

export default {
  components: {
    Loading: Loading,
    Grid: Grid
  },
  data () {
    return {
      pageheading: 'Permission Templates',
      isLoading: true,
      showDeleteModal: false,
      confirmationText: '',
      permissionTemplates: [],

      // Grid Options
      maxHeight: 0,
      totalRecords: 0,
      pageSize: 10,
      skip: 0,
      take: 10,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      sort: [
        {
          field: 'name',
          dir: 'asc'
        }
      ],
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'name',
            operator: 'contains',
            value: ''
          }
        ]
      },
      staticColumns: [
        {
          field: 'name',
          title: 'Name',
          width: '250px',
          filterable: true,
          sortable: true,
          locked: true,
          cell: 'name'
        },
        {
          field: 'description',
          title: 'Description',
          width: '450px',
          filterable: true,
          sortable: true,
          locked: true
        },
        {
          field: 'updatedAt',
          title: 'Updated At',
          filterable: true,
          sortable: true,
          locked: true,
          cell: 'updated-at'
        }
      ],
      selectedField: 'selected',
      selectedFields: [],
      areAllSelected: false
    }
  },
  watch: {
    areAllSelected () {
      if (this.areAllSelected) {
        this.selectedFields = this.permissionTemplates.map(item => item.id)
        this.permissionTemplates = this.permissionTemplates.map(item => {
          item[this.selectedField] = true
          return item
        })
      } else {
        // this.selectedFields = [] // Clear out all the selected fields
        this.permissionTemplates = this.permissionTemplates.map(item => {
          if (!this.selectedFields.includes(item.id)) {
            item[this.selectedField] = false
          }
          return item
        })
      }
    },
    selectedFields (val) {
      this.areAllSelected = val.length === this.permissionTemplates.length
    }
  },
  computed: {
    ...mapState(['activeSite', 'clientSession']),
    pageTitle () {
      return 'Permission Templates - ' + this.activeSite.displayName
    },
    columns () {
      return [
        { field: 'selected', width: '28px', filterable: false, headerCell: 'checkbox', cell: 'selected' },
        ...this.staticColumns
      ]
    },
    sortedTemplates () {
      if (this.sort.length > 0) {
        let sorted = _.sortBy(this.permissionTemplates, (item) => {
          return item[this.sort[0].field]
        })

        if (this.sort[0].dir === 'desc') {
          return sorted.reverse()
        }

        return sorted
      }

      return this.permissionTemplates
    }
  },
  mounted () {
    this.setup()
  },
  methods: {
    toggleItem (permissionTemplate) {
      if (permissionTemplate[this.selectedField]) {
        this.selectedFields = this.selectedFields.filter(item => item !== permissionTemplate.id)
      } else {
        this.selectedFields.push(permissionTemplate.id)
      }
    },
    toggleSelects () {
      if (!this.areAllSelected) {
        this.selectedFields = this.permissionTemplates.map(item => item.id)
        this.permissionTemplates = this.permissionTemplates.map(item => {
          item[this.selectedField] = true
          return item
        })
      } else {
        this.selectedFields = [] // Clear out all the selected fields
        this.permissionTemplates = this.permissionTemplates.map(item => {
          item[this.selectedField] = false
          return item
        })
      }
    },
    moment (date) {
      return moment(date)
    },
    onSelectionChange (event) {
      let checked = event.event.target.checked
      const id = event.dataItem.id
      if (checked) {
        this.selectedFields.push(id)
      } else {
        var idx = this.selectedFields.indexOf(id)
        if (idx > -1) {
          this.selectedFields.splice(idx, 1)
        }
      }
      Vue.set(event.dataItem, this.selectedField, event.dataItem[this.selectedField])
    },
    onHeaderSelectionChange (e) {
      // console.log('header', e)
    },
    pageChangeHandler (e) {
      this.skip = e.skip
      this.take = e.take
      this.getPermissionTemplates()
    },
    sortChangeHandler (e) {
      this.sort = e.sort
    },
    async getPermissionTemplates () {
      this.isLoading = true
      let response = await this.api().permissions().getPermissionTemplates(this.take, this.skip)
      if (response.status === 200) {
        this.permissionTemplates = response.data.data
        this.totalRecords = response.data.results
      } else {
        this.$toast.error('Error getting permission templates')
      }

      this.isLoading = false
    },
    async deleteTemplate () {
      this.isLoading = true
      // let response = await this.$axios.delete('/api/permission-templates/' + this.selectedTemplate.id)

      if (response.status === 200) {
        this.$toast.success('Permission template deleted')
        this.getPermissionTemplates()
      } else {
        this.$toast.error('Error deleting permission template')
      }

      this.isLoading = false
    },
    async newTemplate () {
      this.$router.push({ name: 'PermissionTemplateNewPage' })
    },
    async deleteSelected () {
      this.isLoading = true

      this.selectedFields.map(async (selectedField) => {
        let response = await this.api().permissions().deletePermissionTemplates(selectedField)

        if (response.status === 200) {
          this.successToast('Permission templates deleted')
          this.getPermissionTemplates()
        } else {
          this.errorToast('Error deleting permission templates')
        }
      })
      this.showDeleteModal = false
      this.isLoading = false
    },
    deleteIfConfirmed () {
      if (this.confirmationText.toLowerCase() === 'delete') {
        this.deleteSelected()
      }
    },
    async setupDocument () {
      let that = this
      this.$nextTick(() => {
        let elements = [
          '.has-text-centered.has-background-primary',
          '.button-group'
        ]

        let func = async (results) => {
          let [numberOfResults, maxHeight] = results

          if (this.minResults && this.maxHeight && (numberOfResults === null || maxHeight === null)) {
            return
          }

          this.take = numberOfResults
          this.perPage = numberOfResults
          this.minResults = numberOfResults
          this.maxHeight = maxHeight - 30

          this.getPermissionTemplates()
        }

        that.getListHeights('body-content-area', 40, 79, elements, func.bind(this))

        window.addEventListener('resize', () => {
          that.getListHeights('body-content-area', 40, 79, elements, func.bind(that))
        })
      })
    },
    async setup () {
      this.isLoading = true
      await this.setupDocument()
      this.isLoading = false
    }
  }
}
</script>
<style>
tbody > tr > td {
  background-color: white!important;
}
tbody > tr:nth-of-type(2n) > td {
    background-color: #f6f6f6!important;
}
.k-checkbox:checked{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
</style>

<style>
td:first-of-type, th:first-of-type, .k-header:first-of-type {
  color: transparent!important;
  overflow: hidden!important;
}
</style>
